<div class="full-width">
    <div class="fade-in-image">
        <div class="gradient-down"><app-header></app-header></div>
        <div class="gradient-middle"><h1 class="fade-in">{{ "header.projects" | translate }}</h1></div>
        <div class="gradient-up"></div>
    </div>
    <div class="container-standard pb-5 fade-in">
        <section-title
            [titleText]="'project_page.developments' | translate"
        ></section-title>
        <div class="d-flex flex-column" *ngFor="let project of developments">
            <project-preview [project]="project"></project-preview>
        </div>
        <section-title
            [titleText]="'project_page.projects' | translate"
        ></section-title>
        <div class="d-flex flex-column" *ngFor="let project of projects">
            <project-preview [project]="project"></project-preview>
        </div>
    </div>
</div>
<app-footer></app-footer>
