import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, NEVER, Observable } from 'rxjs';
import { Brew, parseBrew } from 'src/app/models/brew';
import { BrewsServiceInterface } from 'src/app/interfaces/brews-service.interface';
import { SharedService } from 'src/app/common/shared.service';

@Injectable({
    providedIn: 'root',
})
export class BrewsService implements BrewsServiceInterface {

    cache: Observable<Brew[]> = NEVER;

    constructor(private http: HttpClient) {}

    getBrews(): Observable<Brew[]> {
        return this.getStaticBrews();
    }

    getBrewsFromServer(): Observable<Brew[]> {
        if (this.cache == NEVER) {
            this.cache = this.http
                .get<any[]>(SharedService.APIUrl + '/brews')
                .pipe(
                    map((jsons: any[]) =>
                        jsons.map((json) => parseBrew(json))
                    )
                );
        }
        return this.cache;
    }

    getStaticBrews(): Observable<Brew[]> {
        return new Observable<Brew[]>((observer) => {
            observer.next(this.brews);
            observer.complete();
        });
    }

    // Static projects
    brews: Brew[] = [
        {
            id: "av01",
            count: 0,
            description: {
                "en": "Light unfiltered ale with a fruity taste and a pleasant citrus aroma. It is the perfect refreshing drink for a Friday evening after work, especially served chilly.",
                "ua": "Світлий нефільтрований ель з фруктовим смаком і приємним цитрусовим ароматом. Це ідеальний освіжаючий напій для п’ятничного вечора після роботи, особливо в холодному вигляді."
            },
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/brewery/etick_4.png",
            name: "Auferstehung Verschnitt 01"
        },
        {
            id: "av02",
            count: 0,
            description: {
                "en": "Carbonated wheat ale with the addition of caramel malt. The taste is dominated by fruity notes, balanced by a light acidity, perfect for warm autumn weather.",
                "ua": "Пшеничний газований ель з додаванням карамельного солоду. У смаку переважають фруктові нотки, збалансовані легкою кислинкою, що ідеально підходить для теплої осінньої погоди.√"
            },
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/brewery/etick_5.png",
            name: "Auferstehung Verschnitt 02"
        },
        {
            id: "naphta",
            count: 0,
            description: {
                "en": "Beer is dark as Ukrainian night and dense as your mother's. Quite rich and bitter, chocolate-caramel notes are felt in the taste and smell. This is real black gold from the world of beer.",
                "ua": "Пиво темне як Українська ніч і щільне як твоя мамця. Досить насичене і гірке, у смаку та запаху відчуваються шоколадно-карамельнимі ноти. Це справжнє чорне золото від світу пива."
            },
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/brewery/etick3.png",
            name: "NAPHTA BREW"
        },
        {
            id: "dacha1",
            count: 0,
            description: {
             "en": "Extremely light and airy, with a pronounced taste of hops. You can imagine that you only drink kvass, but in fact you get drunk and go to the bushes to feed local hedgehogs.",
             "ua": "Надзвичайно легке та повітряне, з явно вираженим смаком хмелю. Можна уявити, що пʼєш усього лише квас, але насправді пʼянієш і йдеш до кущів годувати місцевих їжаків."
            },
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/brewery/etick1.png",
            name: "DACHA BLEND #1"
        },
        {
            id: "dacha2",
            count: 0,
            description: {
             "en": "Light fruit beer, tastes and textures reminiscent of Indian pale ale. Ideal for warm evenings under the windows of old houses in the city center, or near the sprawling trees in nature.",
             "ua": "Світле фруктове пиво, за смаком та текстурою нагадує індійський блідий ель. Ідеальне для теплих вечорів під вікнами старовинних будинків в центрі міста, або біля розкидистих дерев на природі."
            },
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/brewery/etick2.png",
            name: "DACHA BLEND #2"
        }
    ]
}