import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, NEVER, Observable } from 'rxjs';
import { SharedService } from 'src/app/common/shared.service';
import { Item, parseItem } from 'src/app/models/item';
import { WarehouseServiceInterface } from 'src/app/interfaces/warehouse-service.interface';

@Injectable({
    providedIn: 'root',
})
export class WarehouseService implements WarehouseServiceInterface {
    cache: Observable<Item[]> = NEVER;

    constructor(private http: HttpClient) {}

    getItems(): Observable<Item[]> {
        return this.getStaticItems();
    }

    getItemsFromServer(): Observable<Item[]> {
        if (this.cache == NEVER) {
            this.cache = this.http
                .get<any[]>(SharedService.APIUrl + '/goods')
                .pipe(
                    map((jsons: any[]) =>
                        jsons.map((json) => parseItem(json))
                    )
                );
        }
        return this.cache;
    }

    getStaticItems(): Observable<Item[]> {
        return new Observable<Item[]>((observer) => {
            observer.next(this.items);
            observer.complete();
        });
    }

    items: Item[] = [
        {
            id: "sticker1",
            count: 0,
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/items/sticker1.png",
            name: {
                "en": "\"EKNM\" Sticker",
                "ua": "Стікер \"EKNM\""
            },
            type: 0
        },
        {
            id: "tshirt_loss",
            count: -1,
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/items/tshirt1.png",
            name: {
                "en": "Loss T-Shirt",
                "ua": "Футболка Loss"
            },
            type: 1
        },
        {
            id: "sticker2",
            count: 0,
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/items/sticker2.png",
            name: {
                "en": "\"Night\" Sticker",
                "ua": "Стікер \"Ніч\""
            },
            type: 0
        },
        {
            id: "sticker5",
            count: 5,
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/items/sticker5.png",
            name: {
                "en": "\"Tseh\" Sticker",
                "ua": "Стікер \"Цех\""
            },
            type: 0
        },
        {
            id: "sticker3",
            count: 0,
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/items/sticker3.png",
            name: {
                "en": "\"Factory\" Sticker",
                "ua": "Стікер \"Завод\""
            },
            type: 0
        },
        {
            id: "sticker4",
            count: 0,
            imageUrl: "https://eknmhub1.s3.eu-central-1.amazonaws.com/items/sticker4.png",
            name: {
                "en": "\"Crack\" Sticker",
                "ua": "Стікер \"Злам\""
            },
            type: 0
        }
    ]

}
