<div class="full-width">
    <div class="fade-in-image">
        <div class="gradient-down"><app-header></app-header></div>
        <div class="gradient-middle"><h1 class="fade-in">{{this.title}}</h1></div>
        <div class="gradient-up"></div>
    </div>
    <div class="container-standard">
        <markdown [src]="source"></markdown>
    </div>
</div>
<app-footer></app-footer>


