<div class="d-flex preview-container mb-2 mt-2">
    <img src="{{ project?.logoUrl }}" />
    <div class="d-flex right-container">
        <h3>{{ project?.name }}</h3>
        <!--
        <div class="d-flex flex-row mb-1">
            <p class="status-label">{{ "project_page.status" | translate }} </p>
            <p>{{ state }}</p>
        </div>
        -->
        <div class="d-flex"></div>
        <p>{{ description! }}</p>
        <a
            class="eknm-button-small"
            role="button"
            *ngIf="isButtonActive"
            (click)="navigateToDetails()"
        >
            {{ "project_preview.page" | translate }}
        </a>
        <a
            class="eknm-button-small button-inactive"
            disabled
            *ngIf="!isButtonActive"
        >
            {{ "project_preview.page" | translate }}
        </a>
    </div>
</div>
