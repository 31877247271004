<div class="full-width">
    <div class="fade-in-image">
        <div class="gradient-down"><app-header></app-header></div>
        <div class="gradient-middle"><h1 class="fade-in" rel="preload">{{ "header.beer" | translate }}</h1></div>
        <div class="gradient-up"></div>
    </div>
    <div class="container-standard d-flex flex-column fade-in">
        <p>{{ "brew.description" | translate }}</p>
        <p>{{ "brew.technology" | translate }}</p>
        <p class="pt-3 pb-3">{{ "brew.order" | translate }}</p>
        <section-title [titleText]="'brew.sorts' | translate"></section-title>
        <div class="brews-container d-flex flex-row flex-wrap">
            <div class="brews-item d-flex" *ngFor="let brew of brews">
                <brew-item [brew]="brew"></brew-item>
            </div>
        </div>
        <div class="pb-5"></div>
    </div>
</div>
<app-footer></app-footer>
