import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-page',
    templateUrl: './privacy-policy-pocyr.html',
    styleUrls: [],
})
export class PrivacyPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
