<div class="row pb-4 pt-3">
    <div class="col">
        <div class="separator"></div>
    </div>
    <div class="col-md-auto width-fit-content">
        <h2>{{ titleText }}</h2>
    </div>
    <div class="col">
        <div class="separator"></div>
    </div>
</div>
