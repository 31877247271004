<div class="fade-in-image full-page">
    <div class="gradient-down"></div>
    <div class="gradient-middle">
        <div class="container-standard d-flex justify-content-center align-items-center">
            <div class="container-logo fade-in">
                <h2 class="h16 mb-5">Checking server availability</h2>
                <mat-spinner strokeWidth="8"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="gradient-up"></div>
</div>