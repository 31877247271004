<div class="full-width">
    <div class="article-header" [ngStyle]="setBackground()">
        <div class="gradient-down"><app-header></app-header></div>
        <div class="gradient-middle">
            <div class="container-standard">
                <h1 class="pb-2 pt-2">{{this.title}}</h1>
                <h2 class="pb-2 pt-2">{{this.subtitle}}</h2>
            </div>
        </div>
        <div class="gradient-up"></div>
    </div>
    <div class="container-standard">
        <markdown [data]="content"></markdown>
    </div>
    <div class="mt-3 mb-5"></div>
</div>
<app-footer></app-footer>
