<div class="container d-flex mb-2 mt-2">
    <div class="brew-img-container">
        <img src="{{ brew!.imageUrl }}" />
    </div>
    <p class="brew-title">{{ brew!.name }}</p>
    <p class="label-long pb-3">{{ description }}</p>
    <p>ABV: Unknown</p>
    <p>IBU: Unknown</p>
    <p>FG: Unknown</p>
</div>
