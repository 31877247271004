<div class="full-width">
    <div class="fade-in-image">
        <div class="gradient-down"><app-header></app-header></div>
        <div class="gradient-middle"><h1 class="fade-in">{{ "header.warehouse" | translate }}</h1></div>
        <div class="gradient-up"></div>
    </div>
    <div class="container-standard fade-in">
        <section-title
            [titleText]="'shop_page.stickers' | translate"
        ></section-title>
        <mat-grid-list
            cols="{{ numberOfColumns }}"
            rowHeight="1:1"
            gutterSize="0.5rem"
        >
            <div class="d-flex flex-column" *ngFor="let item of stickers">
                <mat-grid-tile>
                    <shop-item [item]="item"></shop-item>
                </mat-grid-tile>
            </div>
        </mat-grid-list>
        <section-title
            [titleText]="'shop_page.uniform' | translate"
        ></section-title>
        <mat-grid-list
            cols="{{ numberOfColumns }}"
            rowHeight="1:1"
            gutterSize="0.5rem"
        >
            <div class="d-flex flex-column" *ngFor="let item of clothing">
                <mat-grid-tile>
                    <shop-item [item]="item"></shop-item>
                </mat-grid-tile>
            </div>
        </mat-grid-list>
        <div class="pb-5"></div>
    </div>
</div>
<app-footer></app-footer>
