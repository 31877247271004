<div class="fade-in-image full-page">
    <div class="gradient-down"><app-header></app-header></div>
    <div class="gradient-middle">
        <div class="container-standard d-flex justify-content-center align-items-center">
            <div class="container-logo fade-in">
                <h1 class="h0">{{ "home.welcome" | translate }}</h1>
                <p class="p0">{{mainText}}</p>
            </div>
        </div>
    </div>
    <div class="gradient-up"></div>
</div>
<div *ngFor="let highlight of highlights">
    <highlight-item [highlight]="highlight"></highlight-item>
</div>
<div class="pad-page"></div>
<div class="page-footer">
    <div class="gradient-down"></div>
    <div class="gradient-bottom">
        <div class="fade-in">
            <h1 class="h16 mb-5 join-label">{{ "home.join" | translate }}</h1>
            <div class="d-flex flex-row justify-content-center">
                <div class="visit-box">
                    <img src="../../assets/icons/ic_discord.png" class="img-fluid img-icon mb-4"/>
                    <a class="eknm-button-underlined" role="button" href="https://discord.gg/3BsCv9C">
                        Discord Server
                    </a>
                </div>
                <div class="visit-box">
                    <img src="../../assets/icons/ic_insta.png" class="img-fluid img-icon mb-4"/>
                    <a class="eknm-button-underlined" role="button" href="https://www.instagram.com/eknm.zavod/">
                        Instagram
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="gradient-up-bottom">
        <app-footer></app-footer>
    </div>
</div>
